video, img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }

.about {
  /* use relative or absolute path for css media */
  background-image: url('/public/images/cloned_printer.JPG');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.about > h1 {
  color: #fff;
  font-size: 80px;
  margin-top: -100px;
    font-family: Montserrat, Arial, Helvetica, serif;
}

.about > h2 {
  margin-top: 8px;
  color: #fff;
  font-size: 30px;
    font-family: Montserrat, Arial, Helvetica, serif;
  text-align: center;
}

.about > p {
  margin-top: 8px;
  color: #fff;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
  
.info-btns {
  margin-top: 30px;
}
  
.info-btns .btn {
  margin: 10px;
  padding: 0;
  width: 250px;
  height: 50px;
}
  
.fa-play-circle {
  margin-left: 4px;
}

.highlight-link {
  text-decoration: none;
  color: #ffffff!important;
  position: relative;
}

.highlight-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.highlight-link:hover::after {
  width: 100%;
}
  
@media screen and (max-width: 960px) {
  .about > h1 {
      font-size: 70px;
      margin-top: -150px;
      text-align: center;
  }
}
  
@media screen and (max-width: 768px) {
  .about > h1 {
      font-size: 50px;
      margin-top: -100px;
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
  }

  .about > p {
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
  }
  
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  
  .btn {
    width: 100%;
  }
}