@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap');


.joinus_wrapper > p {
  text-align: left;
  font-family: Signika Negative, Arial, Helvetica, serif;
  font-size: 18px;
  padding: 5px 5px;
  font-weight: lighter;
  margin-left: 70px;
  margin-right: 70px;
  line-height: 20px;
  box-sizing:border-box;
}

.joinus_wrapper > h3 {
  text-align: left;
  font-family: Montserrat, Arial, Helvetica, serif;
  font-weight: bold;
  font-size: 25px;
  padding:0px 5px 5px;
  margin-left: 70px;
  margin-right: 70px;
  display: inline-block;
}

.info-btns-joinus .btn {
  max-width: 300px;
  align-items: center;
  margin: auto;
  display: none;
  justify-content: center;
}

img {
  width: 100%;
}