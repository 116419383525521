@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap');

.printing_wrapper > p {
    text-align: left;
    font-family: Signika Negative, Arial, Helvetica, serif;
    font-size: 16px;
    padding: 5px 5px;
    font-weight: lighter;
    margin-left: 70px;
    margin-right: 70px;
    line-height: 20px;
    box-sizing:border-box;
  }

.printing_wrapper > h4 {
   text-align: left;
   font-family: Signika Negative, Arial, Helvetica, serif;
   font-size: 18px;
   padding: 5px 5px;
   font-weight: lighter;
   margin-left: 70px;
   margin-right: 70px;
   line-height: 20px;
   box-sizing:border-box;
   /* to replace the 'p' in printing.js */
 }


 .printing_wrapper > h3 {
    text-align: left;
    font-family: Signika Negative, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding:0px 5px 5px;
    margin-left: 70px;
    margin-right: 70px;
    display: inline-block;
  }

.printing_wrapper > h2 {
    text-align: left;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: bold;
    padding:0px 5px 5px;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 45px;
    display: inline-block;
  }

.printing_wrapper > h5 {
  text-align: center;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: bold;
  padding:0px 5px 5px;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 45px;
  display: inline-block;
}

  img {
    position: relative;
    max-width: 700px;
    height: auto;
    margin-bottom: 20px;
    object-fit:contain;
  }

  strong {
    font-family: Signika Negative, Arial, Helvetica, sans-serif;
    font-size: 20px;
  }

list {
  margin-left: 5%;
  font-family: Signika Negative, Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}

li {
  margin-left: 5%;
  font-family: Signika Negative, Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.table {
  width: 100%;       /* Default width */
  max-width: 600px;
  margin-right: 0;
  font-family: Signika Negative, Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 30px;
  word-wrap: break-word;
  table-layout: auto;
  border-collapse: collapse;
}
.filamentLinks {
  text-align: left; /* Align text to the left */
  font-family: Signika Negative, Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}
td {
  padding: 8px;
  font-family: Signika Negative, Arial, Helvetica, sans-serif;
  font-size: 18px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  margin: 0;
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .table {
    font-size: 16px;
  }

  td {
    padding: 4px;
  }
}



    
  /* .info-btns-printing .btn {
    margin: 10px;
    color: white;
    background-color: #BF3B3B;
    border-color: #BF3B3B;
    max-width: 150px;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    border-width: 3px;
  } */

  a:hover, a:visited, a:link, a:active {
      font-family: Signika Negative, Arial, Helvetica, sans-serif;
    text-decoration: none;
  }

  img {
    width: 100%;
}