@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap');


.about_block {
  font-family: Signika Negative, Arial, Helvetica, serif;
  font-size: 18px;
  }

  p {
    line-height: 25px;
  }

  h2 {
    font-size: 20px;
  }

  h1 {
    padding: 20px;
    font-family: Montserrat, Arial, Helvetica, serif;
  }

  .about_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-left: 70px;
    padding-right: 70px;
    width: 100%;
    margin: 0 auto;
  }
  
  .about_wrapper {
    position: relative;
    margin: 20px 5px 20px;
  }
  
  .about_items {
    margin-bottom: 24px;
  }

  .cards_items {
    margin-bottom: 0px;
  }

  .about_wrapper > img {
    position: relative;
    width: 800px;
    height: auto;
    max-width: 100%;
    display: visible; 
  }
  
  /* .info-btns-about-us .btn {
    margin: 10px;
    color: white;
    background-color: #BF3B3B;
    border-color: #BF3B3B;
    max-width: 150px;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    border-width: 3px;
  } */

  /* Sponsor Images */
  .sponsors_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Sponsor Images Stack */
}

  .sponsors_wrapper > a {
      margin: 0 5px;
  }

  .sponsors_wrapper > a > img {
      width: 150px;
      height: auto;
      max-width: 100%;
      position: relative;
  }

  .sponsorship_wrapper {
    position: relative;
    margin: 30px 5px 30px;
    flex: 0 1 auto
  }
  
  .sponsorship_wrapper > h2 {
    text-align: center;
    font-size: 18px;
  }

  .sponsorship-btns-about-us .btn {
    margin: 10px;
    font-weight: bold;
    border-width: 3px;
    max-width: 300px;
    align-items: center;
    display: inline-block;
    justify-content: center;
  }

  @media only screen and (min-width: 1024px) {
    .cards_items {

      display: flex;
    }
  }