@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap');


.navbar {
    background: #F8F8F8;
    font-family: Montserrat, Arial, Helvetica, serif;
    font-weight: bold;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: sticky;
    top: 0;
    z-index: 999;
    outline: black solid 1px;
}

a:hover {
    color: #AA3030;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  margin-right: 180px;
}

.rapid-logo {
  height: 40px;
  width: 120px;
  overflow: auto;
  position: relative;
  display: block;
  margin: auto;

}


.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}


.nav-links {
  color: gray;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #000;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 880px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #ececec;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

    .nav-links:hover {
        background-color: lightgray;
        color: #AA3030;
        border-radius: 0;
    }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #000;
        padding: 14px 20px;
        border: 1px solid #000;
        transition: all 0.3s ease-out;
    }

  .nav-links-mobile:hover {
    background: #000;
    color: #dbdbdb;
    transition: 250ms;
  }

  .nav-logo {
    margin-right: 0px;
  }
}



@media screen and (max-width: 500px) {
    .nav-menu {
        height: 90vh;
    }
}